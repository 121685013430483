import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {setHandleModal} from '../../store/actions/general-actions';

import styles from './modal.module.scss';

const Modal = (props) => {
  const [modalClose, setmodalClose] = useState(false);
  const dispatch = useDispatch();

  const closeModal = (e) => {
    e.preventDefault();
    setmodalClose(true);
    setTimeout(() => {
      dispatch(setHandleModal());
    }, 600);
  };

  return (
    <>
      <div
        onClick={(e) => closeModal(e)}
        className={
          modalClose ? `${styles.modalContainer} ${styles.containerOut}` : styles.modalContainer
        }
      >
        {" "}
      </div>
      <div className={styles.modalWrapper}>
        <div className={modalClose ? `${styles.modalContent} ${styles.out}` : styles.modalContent}>
          <div className={styles.modalClose}>
            <span onClick={(e) => closeModal(e)}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default Modal;