import Catalog from '../../assets/images/logo-toolscatalog.svg';
import External from '../../assets/images/external.png';
import HamburgerMenu from 'react-hamburger-menu';
import {NavLink} from 'react-router-dom';
import React from 'react';
import styles from './Header.module.scss';
import {useDispatch, useSelector} from '../../utils/react-redux-hooks';

import {openMenu} from '../../store/actions/general-actions'

const Header = (props) => {

    const dispatch = useDispatch();
    const state = useSelector(state => state.general);

    const handleClick = (() => {
        dispatch(openMenu());
    })

    return (
      <div className={styles.container}>
        <div className={styles.logoContainer}>
            <NavLink exact to={'/'}>
                <img src={Catalog} alt='tools-catalog'/>
            </NavLink>
        </div>

        {(props.responsive) ? 
            <HamburgerMenu
                isOpen={state.menuOpen}
                menuClicked={()=>handleClick()}
                width={18}
                height={15}
                strokeWidth={1}
                rotate={0}
                borderRadius={0}
                animationDuration={0.5}
                className={styles.hamburger}
            />
            :
            <div className={styles.optionsContainer}>
                <ul className={styles.listContainer}>
                    {props.menuList.map((option, value)=> {
                        return (
                            <li className={styles.itemstyle} key={value}>
                                <NavLink 
                                    exact
                                    to={option.href}
                                    activeClassName="active-link"
                                >
                                    <p>{option.name}</p>
                                </NavLink>
                            </li>
                        )
                    })}
                    {
                        (props.returnToQmenta) &&
                        <li className={`${styles.itemstyle} ${styles.buttonQmenta}`}>
                            <a href="https://www.qmenta.com/" target="_blank" rel="noreferrer">
                                <p>
                                    qmenta.com 
                                    <img src={External} alt='external'/>
                                </p>
                            </a>
                        </li>
                    }
                </ul>
            </div>
        }
        
      </div>
    )
  }
  
  export default Header;