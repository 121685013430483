import React from "react";
import styles from './Main-layout.module.scss';

const MainLayout = (props) => {
    return (
      <div className={styles.container}>
        {props.children}
      </div>
    );
};

export default MainLayout;