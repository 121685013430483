import React from "react";
import styles from "./filter-by-tag.module.scss";

const FilterByTag = (props) => {
  return (
    <div className={styles.container}>
      <ul className={styles.listBlock}>
        {props.data.map((result, key) => {
          return (
            <li
              className={
                props.filtered === result.name
                  ? styles.cardSelected
                  : styles.cardContainer
              }
              key={key}
              onClick={() => props.handleSetFilterByTag(result)}
            >
              <p>{result.name}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterByTag;
