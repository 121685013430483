import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { openMenu } from "../../store/actions/general-actions";

import Aside from "../aside/aside";
import BackDrop from "../../ui/backdrop/backdrop";
import Footer from "../footer/footer";
import HamburgerLayout from "../../components/hambuger-layout/hamburger-layout";
import FooterLayout from "../../components/footer/Footer-layout";
import Header from "../header/Header";
import HeaderLayout from "../../components/header/Header-layout";
import MainContact from "../main-contact/main-contact";
import MainHome from "../main-home/main-home";
import MainTools from "../main-tools/main-tools";
import MainLayout from "../../components/main/Main-layout";
import MainToolDescription from "../tools-description/tools-description";
import MainSearch from "../main-search/main-search";
import NotFound from "../not-found/not-found";
import Modal from "../../components/modal/modal";
import ApiResponse from "../../ui/api-response/api-response";
import ReCAPTCHA from "react-google-recaptcha";
import { uniqueSetSliders } from "../../store/actions/general-actions";

const Layout = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useDispatch();
  const state = useSelector((state) => state.general);
  const recaptchaRef = React.useRef();

  const menuList = [
    {
      name: "For Developers",
      href: "/developers",
    },
    {
      name: "Contact",
      href: "/contact",
    },
  ];

  useEffect(() => {
    let x = document.getElementsByTagName("HTML")[0];
    if (state.menuOpen) {
      x.style.overflow = "hidden";
    } else {
      x.style.overflow = "scroll";
    }
    // eslint-disable-next-line
  }, [state.menuOpen]);

  useEffect(() => {
    dispatch(uniqueSetSliders());
  }, [props.match.url]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenMenu = () => {
    dispatch(openMenu());
  };

  return (
    <>
      {state.modalOpen && (
        <Modal>
          <ApiResponse
            title={state.sendStatus === "mail_sent" ? "Success" : "Error"}
            message={state.responseMessage}
            condition={state.sendStatus === "mail_sent" ? "success" : "error"}
          />
        </Modal>
      )}
      <HeaderLayout>
        <Header
          menuList={menuList}
          responsive={isMobile}
          returnToQmenta={true}
        />
      </HeaderLayout>
      {state.menuOpen ? (
        <HamburgerLayout>
          <Aside responsive={isMobile} menuList={menuList} />
          <BackDrop action={handleOpenMenu} />
        </HamburgerLayout>
      ) : null}
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_SITE_KEY}
        style={{ zIndex: "10" }}
      />
      <MainLayout>
        <Switch>
          <Route exact path="/developers">
            <MainTools title="For Developers" description="for developers" />
          </Route>
          <Route exact path="/contact">
            <MainContact title="Contact" description="contact" />
          </Route>
          <Route exact path={`/tool/:slug`} component={MainToolDescription} />
          <Route exact path="/search">
            <MainSearch />
          </Route>
          <Route exact path="/">
            <MainHome
              title="QMENTA Imaging Biomarker Analysis Catalog"
              description="Tools for your medical research and clinical trials"
            />
          </Route>
          <Route>
            <NotFound title="error" description="error" />
          </Route>
        </Switch>
      </MainLayout>
      <FooterLayout>
        <Footer menuList={menuList} />
      </FooterLayout>
    </>
  );
};

export default Layout;
