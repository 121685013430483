import Button from "../../ui/button/button";
import InputComponent from "../../ui/input/input";
import queryString from "query-string";
import React, { useState, useEffect } from "react";
import styles from "./filter-bar.module.scss";
import { withRouter } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  searchedTool,
  uniqueSetClearance,
  uniqueSetBiomarkers,
  uniqueSetDisease,
} from "../../store/actions/general-actions";

const FilterBar = (props) => {
  const url = queryString.parse(props.location.search);
  const [isHome] = useState(props.home);
  const [params, setParams] = useState({});
  const dispatch = useDispatch();
  const state = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(uniqueSetBiomarkers());
    dispatch(uniqueSetDisease());
    dispatch(uniqueSetClearance());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setParams(url);
  }, [props.location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(searchedTool(params));
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const labelSort = (a, b) => a.label?.localeCompare(b.label ?? "");

  const handleChange = (name, e) => {
    if (!e) {
      setParams({ ...params, [name]: "" });
    } else {
      setParams({ ...params, [name]: e.value });
    }
  };

  const handleClick = () => {
    let query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return props.history.push(`search?${query}`);
  };

  return (
    <div className={!isHome ? styles.container : styles.containerHome}>
      <div className={styles.row}>
        {props.title && (
          <div className={styles.titleRow}>
            <h2>{props.title}</h2>
          </div>
        )}
        <InputComponent
          type="select"
          title="Disease Group"
          options={state.diseaseFilter?.sort(labelSort)}
          placeholder="All Disease Groups"
          handleChangeSelect={(e) => {
            handleChange("disease", e);
          }}
          value={params.disease || "Select Disease"}
        />
        <InputComponent
          type="select"
          title="Category"
          options={state.biomarkerFilter?.sort(labelSort)}
          placeholder="All Categories"
          handleChangeSelect={(e) => {
            handleChange("biomarker", e);
          }}
          value={params.biomarker || "Select Biomarker"}
        />
        <InputComponent
          type="select"
          title="Body Part"
          options={state.clearanceFilter?.sort(labelSort)}
          placeholder="All Body Parts"
          handleChangeSelect={(e) => {
            handleChange("clearance", e);
          }}
          value={params.clearance || "Select Clearance"}
        />
        <Button
          text="Search"
          handleClick={() => handleClick()}
          result={state.tools?.length}
        />
      </div>
    </div>
  );
};

export default withRouter(FilterBar);
