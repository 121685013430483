import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styles from "./contact-form.module.scss";
import CompanyTypes from "../../utils/CompanyTypes.json";
import JobLevels from "../../utils/JobLevels.json";
import {
  sendContactForm,
  sendToolForm,
} from "../../store/actions/general-actions";
import { useDispatch } from "react-redux";

const ContactForm = (props) => {
  const dispatch = useDispatch();
  const [jobLevel, setJobLevel] = useState("");
  const [industry, setIndustry] = useState("");
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    props.handleSubmit();
    if (props.tool) {
      dispatch(sendToolForm(data, props.tool.name, props.tool.id));
    } else {
      dispatch(sendContactForm(data));
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.handleBackground} />
      <div className={styles.formContainer}>
        <div className={styles.title}>
          <h3>{props.title}</h3>
          <div className={styles.separator}>
            <hr size="1" />
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <h6>Email *</h6>
            <input
              name="email"
              type="email"
              data-testid="email"
              defaultValue=""
              placeholder="Provide your email address"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Provide a valid email address",
                },
              })}
            />
            {errors.email && (
              <p role="alert" className={styles.error}>
                {errors.email.message}
              </p>
            )}
          </div>
          <div className={styles.inputContainer}>
            <h6>First Name *</h6>
            <input
              name="name"
              type="text"
              data-testid="name"
              defaultValue=""
              placeholder="Type your name"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "Only characters as name",
                },
                minLength: {
                  value: 2,
                  message: "Provide minimum 2 characters as name",
                },
              })}
            />
            {errors.name && (
              <p role="alert" className={styles.error}>
                {errors.name.message}
              </p>
            )}
          </div>

          <div className={styles.inputContainer}>
            <h6>Last Name *</h6>
            <input
              name="surname"
              type="text"
              data-testid="surname"
              defaultValue=""
              placeholder="Type your surname"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: "Only characters as surname",
                },
                minLength: {
                  value: 2,
                  message: "Provide minimum 2 characters as surname",
                },
              })}
            />
            {errors.surname && (
              <p role="alert" className={styles.error}>
                {errors.surname.message}
              </p>
            )}
          </div>

          <div className={styles.inputContainer}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
              className={styles.customRow}
            >
              <div style={{ flex: "50%" }}>
                <h6>Company Name *</h6>
                <input
                  name="company"
                  type="text"
                  data-testid="company"
                  defaultValue=""
                  placeholder="Provide your institution"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.company && (
                  <p
                    role="alert"
                    className={styles.error}
                    style={{ flex: "50%" }}
                  >
                    {errors.company.message}
                  </p>
                )}
              </div>
              <div style={{ flex: "50%" }}>
                <h6>Company Type *</h6>
                <select
                  name="industry"
                  data-testid="industry"
                  defaultValue=""
                  onChange={(e) => setIndustry(e.value)}
                  value={industry}
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  {CompanyTypes.map((w, i) => (
                    <option key={i} value={w.value}>
                      {w.label}
                    </option>
                  ))}
                </select>
                {errors.industry && (
                  <p
                    role="alert"
                    className={styles.error}
                    style={{ flex: "50%", paddingLeft: "10px" }}
                  >
                    {errors.industry.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.inputContainer}>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
              className={styles.customRow}
            >
              <div style={{ flex: "50%" }}>
                <h6>Job Title *</h6>
                <input
                  name="jobtitle"
                  type="text"
                  data-testid="jobtitle"
                  defaultValue=""
                  placeholder="Type your position"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.jobtitle && (
                  <p
                    role="alert"
                    className={styles.error}
                    style={{ flex: "50%" }}
                  >
                    {errors.jobtitle.message}
                  </p>
                )}
              </div>
              <div style={{ flex: "50%" }}>
                <h6>Job Level *</h6>
                <select
                  name="job_level"
                  defaultValue=""
                  data-testid="job_level"
                  onChange={(e) => setJobLevel(e.value)}
                  value={jobLevel}
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  {JobLevels.map((w, i) => (
                    <option key={i} value={w.value}>
                      {w.label}
                    </option>
                  ))}
                </select>
                {errors.job_level && (
                  <p role="alert" className={styles.error} style={{}}>
                    {errors.job_level.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <h6>Message</h6>
            <textarea
              name="message"
              type="text"
              data-testid="message"
              defaultValue=""
              placeholder="Type your message"
              ref={register()}
            />
          </div>
          <input type="hidden" value="something" />
          <div className={styles.agreements}>
            <div>
              <input
                type="checkbox"
                name="communications"
                data-testid="communications"
                ref={register()}
              />
              <p>
                I agree to receive communications from QMENTA. Remember you can
                unsubscribe at any time.
                <span>
                  Please review our{" "}
                  <a
                    href="https://www.qmenta.com/data-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Policy
                  </a>
                </span>
              </p>
            </div>
            <div>
              <input
                type="checkbox"
                name="personaldata"
                data-testid="personaldata"
                ref={register({
                  required: {
                    value: true,
                    message: "Please complete this required field",
                  },
                })}
              />
              <p>
                I agree to allow QMENTA to store and process my personal data.{" "}
                <b style={{ color: "red" }}>*</b>
                <span>
                  Please review our{" "}
                  <a
                    href="https://www.qmenta.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              </p>
            </div>
            {errors.personaldata && (
              <p role="alert" className={styles.error}>
                {errors.personaldata.message}
              </p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <button type="submit" className={styles.button}>
              send enquiry
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
