import React from "react";
import styles from "./homepage-section.module.scss";

const HomepageSection = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2>{props.sectionTitle}</h2>
        {props.sectionSubTitle && <p>{props.sectionSubTitle}</p>}
      </div>
      <div
        className={
          props.searchContainer
            ? styles.searchContainer
            : styles.cardInterestContainer
        }
      >
        {props.children}
      </div>

      {props.separator && (
        <div className={styles.separator}>
          <hr size="1" />
        </div>
      )}
    </div>
  );
};

export default HomepageSection;
