import React from "react";
import ContentAside from "./contentaside";

const Aside = (props) => {
  return (
    <React.Fragment>
      <ContentAside
        menuList={props.menuList}
      />
    </React.Fragment>
  );
};

export default Aside;
