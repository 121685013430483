import React from "react";
import styles from './icons-homepage.module.scss';

const IconsHome = (props) => {
    return (
        <div className={(props.iconsWidth) ? styles.iconsWidth : styles.icons}>
            <img width="54" src={props.icon} alt='homepage-icon'/>
            <p>{props.description}</p>
        </div>
    );
};

export default IconsHome;