import React from "react";
import styles from './button.module.scss';

const ButtonComponent = (props) => {
    return (
        <div className={styles.container}>
            <div 
                className={(props.result) ? styles.button : `${styles.button} ${styles.disabledbutton }`} 
                onClick={e => !props.result ? e.preventDefault() : props.handleClick()}
            >
                <p>{`${props.text} (${props.result} Tools)`}</p>
            </div>
        </div>
    );
};

export default ButtonComponent;