import * as actionType from "./types";
import axios from "axios";
import Cookies from "js-cookie";
import { client } from "../../utils/sanityClient";

const isBrowser = typeof window !== "undefined";
const hutk = isBrowser ? Cookies.get("hubspotutk") : null;
const pageUri = isBrowser ? window.location.href : null;
const pageName = isBrowser ? document.title : null;

const formatResponse =
  "{technicalInformation, catalogStatus, description, keyFeatures, basedOn, logo, name, slug, thumbnail, regulatoryClearance, reportimages, filter[]->{label, value}, tags[]->{name}}";

export const uniqueSetFilters = () => async (dispatch) => {
  const query = '*[_type == "tags"]{name}';

  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_FILTERS,
      payload: result,
    });
  });
};

export const uniqueSetClearance = () => async (dispatch) => {
  const query = '*[_type == "clearanceFilter"]{label, value}';

  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_CLEARANCE_FILTER,
      payload: result,
    });
  });
};

export const uniqueSetBiomarkers = () => async (dispatch) => {
  const query = '*[_type == "biomarkerFilter"]{label, value}';

  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_BIOMARKER_FILTER,
      payload: result,
    });
  });
};

export const uniqueSetSliders = () => async (dispatch) => {
  const query =
    '*[_type == "sliders"]{name, tool[]->{technicalInformation, catalogStatus, description, keyFeatures, logo, basedOn, regulatoryClearance, reportimages, name, slug, thumbnail, filter[]->{label, value}, tags[]->{name}}}';

  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_SLIDERS,
      payload: result,
    });
  });
};

export const uniqueSetDisease = () => async (dispatch) => {
  const query = '*[_type == "diseaseFilter"]{label, value}';

  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_DISEASE_FILTER,
      payload: result,
    });
  });
};

const setQueryParams = (params, tagValue = "") => {
  const disease = params.disease
    ? ` && "${params.disease}" in filter[]->value`
    : "";
  const biomarker = params.biomarker
    ? ` && "${params.biomarker}" in filter[]->value`
    : "";
  const clearance = params.clearance
    ? ` && "${params.clearance}" in filter[]->value`
    : "";
  const tags = tagValue ? ` && "${tagValue}" in tags[]->name` : ``;

  const query = `*[_type == "tools" && catalogStatus ${disease}${biomarker}${clearance}${tags}]${formatResponse}`;
  return query;
};

export const searchedTool = (params) => async (dispatch) => {
  const query = setQueryParams(params);
  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_TOOLS,
      payload: result,
    });
  });
};

export const taggedTool = (value, params) => async (dispatch) => {
  const query = setQueryParams(params, value);
  client.fetch(query).then((result) => {
    dispatch({
      type: actionType.SET_TOOLS,
      payload: result,
    });
  });
};

export const openMenu = () => {
  return {
    type: actionType.MENU_OPEN,
  };
};

export const queryTool = (props) => async (dispatch) => {
  const query = `*["${props.match.params.slug}" == slug.current]${formatResponse}`;
  client.fetch(query).then((result) => {
    if (!result.length) {
      props.history.push("/404");
    } else {
      dispatch({
        type: actionType.SET_SELECTED_TOOL,
        payload: result,
      });
    }
  });
};

export const setHandleModal = () => {
  return {
    type: actionType.HANDLE_MODAL,
  };
};

export const sendContactForm = (value) => (dispatch) => {
  axios
    .post(
      "https://api.hsforms.com/submissions/v3/integration/submit/1553939/b867541e-ae35-47a6-941a-8a059131fb62",
      {
        submittedAt: Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
        fields: [
          {
            name: "email",
            value: value.email,
          },
          {
            name: "firstname",
            value: value.name,
          },
          {
            name: "lastname",
            value: value.surname,
          },
          {
            name: "company",
            value: value.company,
          },
          {
            name: "industry",
            value: value.industry,
          },
          {
            name: "jobtitle",
            value: value.jobtitle,
          },
          {
            name: "job_level",
            value: value.job_level,
          },
          {
            name: "message",
            value: value.message,
          },
        ],
        context: {
          hutk,
          pageUri,
          pageName,
        },
        legalConsentOptions: {
          consent: {
            // Include this object when GDPR options are enabled
            consentToProcess: true,
            text: "I agree to allow QMENTA to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from QMENTA.",
              },
            ],
          },
        },
      }
    )
    .then((response) => {
      dispatch({
        type: actionType.MAIL_SENT,
        message: response.data.inlineMessage,
        status: "mail_sent",
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const sendNewsLetterForm = (value) => (dispatch) => {
  axios
    .post(
      "https://api.hsforms.com/submissions/v3/integration/submit/1553939/3ab3aeb2-2ae2-412b-ba81-4ea55ba14123",
      {
        submittedAt: Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
        fields: [
          {
            name: "email",
            value: value.email,
          },
        ],
        context: {
          hutk,
          pageUri,
          pageName,
        },
        legalConsentOptions: {
          consent: {
            // Include this object when GDPR options are enabled
            consentToProcess: true,
            text: "I agree to allow QMENTA to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from QMENTA.",
              },
            ],
          },
        },
      }
    )
    .then((response) => {
      dispatch({
        type: actionType.NEWSLETTER_SENT,
        message: response.data.inlineMessage,
        status: "mail_sent",
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const sendToolForm = (value, tool, id) => (dispatch) => {
  axios
    .post(
      "https://api.hsforms.com/submissions/v3/integration/submit/1553939/eac1881b-5cd0-42cc-8eff-3d66a2602c24",
      {
        submittedAt: Date.now(), // This millisecond timestamp is optional. Update the value from "1517927174000" to avoid an INVALID_TIMESTAMP error.
        fields: [
          {
            name: "email",
            value: value.email,
          },
          {
            name: "firstname",
            value: value.name,
          },
          {
            name: "lastname",
            value: value.surname,
          },
          {
            name: "company",
            value: value.company,
          },
          {
            name: "industry",
            value: value.industry,
          },
          {
            name: "jobtitle",
            value: value.jobtitle,
          },
          {
            name: "job_level",
            value: value.job_level,
          },
          {
            name: "message",
            value: value.message,
          },
          {
            name: "tool_interest",
            value: tool,
          },
        ],
        context: {
          hutk,
          pageUri,
          pageName,
        },
        legalConsentOptions: {
          consent: {
            // Include this object when GDPR options are enabled
            consentToProcess: true,
            text: "I agree to allow Example Company to store and process my personal data.",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "I agree to receive marketing communications from Example Company.",
              },
            ],
          },
        },
      }
    )
    .then((response) => {
      dispatch({
        type: actionType.MAIL_TOOL_SENT,
        id: id,
        message: response.data.inlineMessage,
        status: "mail_sent",
      });
    })
    .catch((error) => {
      throw error;
    });
};
