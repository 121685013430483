import React from "react";
import Select from "react-select";
import styles from "./input.module.scss";

const customStyles = {
  container: (provided) => ({
    ...provided,
    height: 44,
  }),
  control: (provided) => ({
    ...provided,
    height: "100%",
    borderRadius: "2px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Nunito-Sans",
    fontWeight: 600,
    fontSize: 16,
    color: "#8f9194",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontFamily: "Nunito-Sans",
    fontWeight: 600,
    fontSize: 16,
    color: "#292929",
    height: "100%",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Nunito-Sans",
    fontWeight: 600,
    fontSize: 16,
    color: "#292929",
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Roboto-Regular",
    fontWeight: 400,
    fontSize: 16,
    color: "#292929",
    borderBottom: "1px solid #F2F2F2",
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 8,
    position: "relative",
    background: "transparent",
    "&:hover": {
      backgroundColor: "#f2f2f2",
    },
    "&:last-child": {
      borderBottom: "0px solid #F2F2F2",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    paddingBottom: 0,
  }),
};

const InputComponent = (props) => {
  switch (props.type) {
    case "select":
      return (
        <div className={styles.selectContainer}>
          <h2>{props.title}</h2>
          <Select
            {...props}
            isSearchable={true}
            placeholder={props.placeholder}
            onChange={(e) => props.handleChangeSelect(e)}
            options={props.options}
            styles={customStyles}
            isClearable
            value={props.options.filter(
              (option) => option.value === props.value
            )}
            defaultValue={props.value}
          />
        </div>
      );
    default:
      return;
  }
};

export default InputComponent;
