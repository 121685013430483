import CardSlider from "../card-slider/card-slider";
import FilterBar from "../filter-bar/filter-bar";
import HomepageSection from "../../components/homepage-section/homepage-section";
import IconsHome from "../../components/icons-homepage/icons-homepage";
import React, { useEffect, useRef } from "react";
import styles from "./main-tools.module.scss";

import distribute from "../../assets/images/Icon-round-distribute.png";
import quickly from "../../assets/images/Icon-round-quickly-share@2x.png";
import develop from "../../assets/images/icon-round-easily-develop@2x.png";
import brackets from "../../assets/images/curly-brackets-down-xl.svg";
import arrowThreeSteps from "../../assets/images/arrow-tools-integration.png";
import boxDeveloper from "../../assets/images/box-developer-a-b.svg";
import boxQmentaPlatform from "../../assets/images/box-qmenta-platform.svg";
import boxIntegrated from "../../assets/images/box-integrated-libraries.png";
import boxTechnologies from "../../assets/images/box-technologies@2x.png";
import boxUsers from "../../assets/images/box-users-a-b.svg";
import sdkDocumentation from "../../assets/images/sdk-documentation-screen.svg";
import sdkGithub from "../../assets/images/sdk-github-screen.svg";
import OpenInNew from "../../assets/images/open_in_new-white.svg";

import { Helmet } from "react-helmet";

const MainTools = (props) => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <FilterBar />
      <div className={styles.sectionContainer}>
        <HomepageSection
          sectionTitle="AI Tools integration with Software Development Kit (SDK)"
          sectionSubTitle="We have designed a Software Development Kit (SDK) to accelerate R&D on your team, and easily plug-in, validate, deploy and market your own AI tools and applications, on the QMENTA platform."
          separator={true}
        >
          <div className={styles.iconsPresentation}>
            <IconsHome
              iconsWidth={true}
              icon={develop}
              description="Easily develop, test and run tools in a scalable cloud environment"
            />
            <IconsHome
              iconsWidth={true}
              icon={quickly}
              description={`Quickly share your tools & workflows with the neuroimaging community`}
            />
            <IconsHome
              iconsWidth={true}
              icon={distribute}
              description="Distribute and monetize your research with a simple revenue-sharing model"
            />
          </div>
        </HomepageSection>
        <HomepageSection sectionTitle="Our SDK advantages" separator={true}>
          <div className={styles.advantageContainer}>
            <div className={styles.upBracket}>
              <p>Add to an existing Docker image or use a template</p>
              <p className={styles.qmentaColor}>+</p>
              <p>Simple and straight forward syntax</p>
              <p className={styles.qmentaColor}>+</p>
              <p>Supports any software that runs on Linux</p>
            </div>
            <div className={styles.bracket}>
              <img src={brackets} alt="bracket" />
            </div>
            <div className={styles.downBracket}>
              <span>
                Present your tools to users with already established interface
                options
              </span>
            </div>
          </div>
        </HomepageSection>
        <HomepageSection
          sectionTitle="Tools integration explained in 3 easy steps"
          separator={true}
        >
          <div className={styles.stepsOverall}>
            <div className={styles.stepsContainer}>
              <div className={styles.step}>
                <div className={styles.imageBlock}>
                  <div className={styles.stepImage}>
                    <img src={boxDeveloper} alt="boxQmentaPlatform" />
                  </div>
                  <div className={styles.stepText}>
                    <p>
                      Developers can push their code to Docker Hub or any
                      registry.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.arrow}>
                <img src={arrowThreeSteps} alt="arrow" />
              </div>
              <div className={styles.step}>
                <div className={styles.imageBlock}>
                  <div className={styles.stepImage}>
                    <img src={boxQmentaPlatform} alt="boxQmentaPlatform" />
                  </div>
                  <div className={styles.stepText}>
                    <p>
                      Developers create the tool in the platform linking to the
                      image in Docker hub
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.arrow}>
                <img src={arrowThreeSteps} alt="arrow" />
              </div>
              <div className={styles.step}>
                <div className={styles.imageBlock}>
                  <div className={styles.stepImage}>
                    <img src={boxUsers} alt="boxQmentaPlatform" />
                  </div>
                  <div className={styles.stepText}>
                    <p>
                      When users start the analysis in QMENTA platform, it pulls
                      the proper docker image, the patient data, executes, and
                      puts back the results
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HomepageSection>
        <HomepageSection separator={true}>
          <div className={styles.techonologyInformation}>
            <div className={styles.imageContainer}>
              <img src={boxTechnologies} alt="integrated-technologies" />
            </div>
            <div className={styles.imageContainer}>
              <img src={boxIntegrated} alt="integrated-libraries" />
            </div>
            <div className={styles.imageContainer}>
              <div className={styles.shape}>
                <div className={styles.documentationContainer}>
                  <div className={styles.sdkContainer}>
                    <img src={sdkDocumentation} alt="sdk-documentation" />
                    <p>
                      QMENTA’s SDK <br /> Documentation
                    </p>
                  </div>
                  <div className={styles.sdkExplanation}>
                    <h2>SDK Documentation</h2>
                    <h3>
                      Not sure about something and want to find out more about
                      our SDK documentation?
                    </h3>
                    <div className={styles.button}>
                      <a
                        href="https://docs.qmenta.com/sdk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        To the documentation
                      </a>
                      <img src={OpenInNew} alt="icon-cloud" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.imageContainer}>
              <div className={styles.shape}>
                <div className={styles.documentationContainer}>
                  <div className={styles.sdkContainer}>
                    <img src={sdkGithub} alt="sdk-github" />
                    <p>
                      QMENTA’s GitHub <br /> Repository
                    </p>
                  </div>
                  <div className={styles.sdkExplanation}>
                    <h2>GitHub repository</h2>
                    <h3>
                      Fork our example repository to start the development of
                      your own imaging tool on your local machine!
                    </h3>
                    <div className={styles.button}>
                      <a
                        href="https://github.com/QMENTA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        to GitHub repository
                      </a>
                      <img src={OpenInNew} alt="icon-cloud" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HomepageSection>
        <HomepageSection sectionTitle="Integrated tools" separator={!false}>
          <CardSlider filterSliderName={"Integrated tools"} />
        </HomepageSection>
      </div>
    </div>
  );
};

export default MainTools;
