import React from "react";
import styles from './Footer-layout.module.scss';

const FooterLayout = (props) => {
    return (
      <div className={styles.container}>
          {props.children}
      </div>
    );
};

export default FooterLayout;