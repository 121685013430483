import React, {useEffect} from "react";
import lottie from "lottie-web";
import successData from "../../assets/check.json";
import errorData from "../../assets/error.json";
import styles from './api-response.module.scss';

const ApiResponse = (props) => {

    useEffect(() => {
      lottie.loadAnimation({
        container: document.querySelector("#errorLottie"),
        animationData: errorData,
        loop: false,
        autoplay: true
      });
      lottie.loadAnimation({
        container: document.querySelector("#successLottie"),
        animationData: successData,
        loop: false,
        autoplay: true
      });
    }, [])

    const renderSwitch = (condition) => {
        switch(condition) {
          case 'success':
            return (
            <div className={styles.finishContent}>
                <div id="successLottie"/>
                <h3>{props.title}</h3>
                <p>{props.message}</p>
            </div>
            );
          case 'error':
            return (
              <div className={styles.finishContent}>
                <div id="errorLottie"/>
                <h3>{props.title}</h3>
                <p>{props.message}</p>
              </div>
            );
          default:
            return (
              <div className={styles.finishContent}>
                <div id="errorLottie"/>
                <h3>UPS!</h3>
                <p>There was an error, try again later!</p>
              </div>
            );
        }
    }

    return (
        <>
            {renderSwitch(props.condition)}
        </>
    )
};

export default ApiResponse;







