import { Link } from "react-router-dom";
import ErrorImage from "../../assets/images/ErrorImage.png";
import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import styles from "./not-found.module.scss";

import errorData from "../../assets/images/error.json";

import { Helmet } from "react-helmet";

const NotFound = (props) => {
  const myRef = useRef(null);

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    lottie.loadAnimation({
      container: document.querySelector("#errorLottie"),
      animationData: errorData,
      loop: false,
      autoplay: true,
    });
  }, []);

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <div className={styles.errorTextWrapper}>
        <div>
          <h1>404</h1>
          <h3>Page not found</h3>
          <div className={styles.buttonContainer}>
            <Link to={"/"} className={styles.button}>
              go home
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.errorImage}>
        <img src={ErrorImage} className={styles.errorImg} alt="error"></img>
      </div>
    </div>
  );
};

export default NotFound;
