import ContactForm from "../contact-form/contact-form";
import { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import Loading from "../../assets/images/loading_white.gif";
import styles from "./tools-description.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ApiResponse from "../../ui/api-response/api-response";
import { isEmpty } from "lodash";
import { queryTool } from "../../store/actions/general-actions";
import { client } from "../../utils/sanityClient";
import imageUrlBuilder from "@sanity/image-url";

import { Helmet } from "react-helmet";

const MainToolDescription = (props) => {
  const myRef = useRef(null);
  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.general);
  const builder = imageUrlBuilder(client);

  useEffect(() => {
    const loadTool = () => new Promise((resolve, reject) => {
      try {
        setLoading(true)
        resolve(dispatch(queryTool(props)))
      } catch (error) {
        setLoading(false)
        reject(error)
      }
    })
    Promise.all([loadTool()]).then(() => {
      setInterval(() => {
        setLoading(false)
      }, 500);
    })
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [props.match.params.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    if (state.sentToolsId.includes(state.selectedTool.id)) {
      setFormSent(true);
    } else setFormSent(false);
  };

  function urlFor(source) {
    return builder.image(source);
  }

  const options = {
    autoplaySpeed: 3000,
    boxShadow: "none",
    disableKeyboardControls: false,
    disablePanzoom: false,
    disableWheelControls: true,
  }

  const IMG = (imgName) => {
    if (isEmpty(imgName)) {
      return `./assets/tools-images/No-Photo-Available.png`;
    }
    return urlFor(imgName);
  }

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>
          {state.selectedTool.name
            ? state.selectedTool.name
            : "No tool matching this request"}
        </title>
        <meta name="description" content="Tools lala" />
      </Helmet>
      <div className={styles.backContainer}>
        <Link to="/search">
          <p>{`< Back to search results`}</p>
        </Link>
      </div>
      <div className={styles.mainToolContainer}>
        {loading ? (
          <img
            width="24px"
            height="24px"
            src={Loading}
            alt="loading"
          />
        ) : (
          <div className={styles.toolDescriptionContainer}>
            <div className={styles.titleContainer}>
              <img src={IMG(state.selectedTool.logo)} alt="title-logo" />
              <h2>{state.selectedTool.name}</h2>
            </div>
            <div className={styles.descriptionContainer}>
              <h2>Description</h2>
              <div style={{ marginTop: '1rem' }}
                dangerouslySetInnerHTML={{
                  __html: state.selectedTool.description,
                }}
              />
            </div>
            <div className={styles.keyFeaturesContainer}>
              <h2>Key Features</h2>
              <ul>
                {state.selectedTool.keyFeatures &&
                  state.selectedTool.keyFeatures.map((value, key) => (
                    <li key={key}> &#8226; {value}</li>
                  ))}
              </ul>
            </div>
            <div className={styles.tableContainer}>
              <h2>Regulatory clearance:&nbsp; <span>{state.selectedTool.regulatoryClearance}</span></h2>
            </div>
            <div className={styles.reportContainer}>
              {!isEmpty(state.selectedTool.reportimages) && (
                <SRLWrapper
                  options={{
                    buttons: {
                      showAutoplayButton: false,
                      showCloseButton: true,
                      showDownloadButton: false,
                      showFullscreenButton: true,
                    },
                    settings: {
                      ...options,
                      downloadedFileName: state.selectedTool.name,
                    },
                  }}
                >
                  <h2>Example</h2>
                  <div className={styles.reportRow}>
                    {(state.selectedTool.reportimages.length) && state.selectedTool.reportimages.length < 2 ? (
                      state.selectedTool.reportimages.map((selectedData, key) => (
                        <img
                          src={urlFor(selectedData)}
                          alt={`${state.selectedTool.name}`}
                          key={key}
                          style={{ cursor: "pointer" }}
                        />
                      ))
                    ) : (
                      state.selectedTool.reportimages.slice(1).map((selectedData, key) => (
                        <img
                          src={urlFor(selectedData)}
                          alt={`${state.selectedTool.name}`}
                          key={key}
                          style={{ cursor: "pointer" }}
                        />
                      ))
                    )}
                  </div>
                </SRLWrapper>
              )}
            </div>
          </div>
        )}

        {state.sentToolsId.includes(state.selectedTool.id) ? (
          <div className={styles.contactFormContainer}>
            <ApiResponse
              title={state.sendToolStatus === "mail_sent" ? "Success" : "Error"}
              message={state.responseToolMessage}
              condition={
                state.sendToolStatus === "mail_sent" ? "success" : "error"
              }
            />
          </div>
        ) : (
          <div
            className={
              formSent
                ? `${styles.contactFormContainer} ${styles.contactFormContainerOut}`
                : styles.contactFormContainer
            }
          >
            <ContactForm
              title="general enquiry form"
              tool={state.selectedTool}
              handleSubmit={() => handleSubmit()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainToolDescription;
