import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Layout from './containers/layout/Layout';
import './App.scss';


const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route path="/" component={Layout}/>
      </Switch>
    </BrowserRouter>
  )
}

export default App;
