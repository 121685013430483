import * as actionType from "../actions/types";

export const GENERAL_STATE = {
  clearanceFilter: [],
  diseaseFilter: [],
  biomarkerFilter: [],
  filters: [],
  sliders: [],
  menuOpen: false,
  modalOpen: false,
  responseMessage: "",
  sendStatus: "",
  contactMailSent: false,
  sentToolsId: [],
  tools: [],
  selectedTool: {},
};

const store = (state = GENERAL_STATE, action) => {
  switch (action.type) {
    case actionType.MENU_OPEN:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case actionType.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case actionType.SET_SLIDERS:
      return {
        ...state,
        sliders: action.payload,
      };
    case actionType.HANDLE_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };
    case actionType.MAIL_SENT:
      return {
        ...state,
        contactMailSent: true,
        responseMessage: action.message,
        sendStatus: action.status,
      };
    case actionType.MAIL_TOOL_SENT:
      return {
        ...state,
        responseToolMessage: action.message,
        sendToolStatus: action.status,
        sentToolsId: [...state.sentToolsId, action.id],
      };
    case actionType.NEWSLETTER_SENT:
      return {
        ...state,
        modalOpen: true,
        responseMessage: action.message,
        sendStatus: action.status,
      };
    case actionType.SET_TOOLS:
      return {
        ...state,
        tools: action.payload,
      };
    case actionType.SET_CLEARANCE_FILTER:
      return {
        ...state,
        clearanceFilter: action.payload,
      };
    case actionType.SET_DISEASE_FILTER:
      return {
        ...state,
        diseaseFilter: action.payload,
      };
    case actionType.SET_BIOMARKER_FILTER:
      return {
        ...state,
        biomarkerFilter: action.payload,
      };
    case actionType.SET_SELECTED_TOOL:
      return {
        ...state,
        selectedTool: action.payload[0],
      };

    default:
      return state;
  }
};

export default store;
