import React from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import './chevron-layout.css';

const chevronLayout = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style}}
            onClick={onClick}
        >
            {props.left && <FaChevronCircleLeft style={{color: '#00758c', minWidth: '38px', height: '38px', position: 'relative', right: '1rem'}}/>}
            {props.right && <FaChevronCircleRight style={{color: '#00758c', minWidth: '38px', height: '38px', position: 'relative',}}/>}
        </div>
    );
};

export default chevronLayout;