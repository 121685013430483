import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import reducer from './store/reducers'
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';

import {createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux'
import TagManager from 'react-gtm-module'
import SimpleReactLightbox from 'simple-react-lightbox'
import './index.css';

const store = createStore(reducer, applyMiddleware(thunk))

const tagManagerArgs = {
  gtmId: 'GTM-TVWFKGH'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Provider store={store}>
    <SimpleReactLightbox>
      <App />
    </SimpleReactLightbox>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)).
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
