import React, { useState } from "react";
import styles from "./card.module.scss";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { client } from "../../utils/sanityClient";
import imageUrlBuilder from "@sanity/image-url";

const Card = (props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleToolTip = () => setTooltipOpen(!tooltipOpen);
  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }

  const drawPills = (tags) => {
    if (!tags?.length) {
      return <div className={styles.emptyTagManager} />;
    } else if (tags.length >= 1 && tags.length <= 3) {
      return tags.map((value, key) => {
        if (value.length >= 30) {
          return (
            <div className={styles.tagManager}>
              {tooltipOpen && (
                <div className={styles.tooltipContainer}>
                  <div className={styles.toolTipTextContainer}>
                    {tags.slice(0).map((value, key) => {
                      if (key !== tags.slice(0).length - 1) {
                        return <span key={key}>{value.name} | </span>;
                      } else {
                        return <span key={key}>{value.name} </span>;
                      }
                    }, "")}
                  </div>
                </div>
              )}
              <span
                className={styles.cardManagerTags}
                onMouseEnter={toggleToolTip}
                onMouseLeave={toggleToolTip}
              >
                ...
              </span>
            </div>
          );
        }
        return (
          <div key={key} className={styles.tagManager}>
            <span className={styles.cardManagerTags}>{value.name}</span>
          </div>
        );
      });
    } else if (tags.length > 3) {
      return (
        <div className={styles.tagManager}>
          <span className={styles.cardManagerTags}>{tags[0].name}</span>
          <span className={styles.cardManagerTags}>{tags[1].name}</span>
          {tooltipOpen && (
            <div className={styles.tooltipContainer}>
              <div className={styles.toolTipTextContainer}>
                {tags.slice(2).map((value, key) => {
                  if (key !== tags.slice(2).length - 1) {
                    return <span key={key}>{value.name} | </span>;
                  } else {
                    return <span key={key}>{value.name} </span>;
                  }
                }, "")}
              </div>
            </div>
          )}
          <span
            className={styles.cardManagerTags}
            onMouseEnter={toggleToolTip}
            onMouseLeave={toggleToolTip}
          >
            ...
          </span>
        </div>
      );
    }
  };

  const IMG = (imgName) => {
    if (isEmpty(imgName)) {
      return `./assets/tools-images/No-Photo-Available.png`;
    }
    return urlFor(imgName);
  };

  return (
    <div className={styles.cardContainer} key={props.keyProp}>
      <div className={styles.shadowFaker}>
        <div className={styles.logoContainer} style={{ 
          background: `center / contain no-repeat url('${IMG(props.reportimages)}')`
          }}>
          <div className={styles.logo}>
            <img src={IMG(props.logo)} alt="card-logo" />
          </div>
        </div>
        <div className={styles.titleContainer}>
          <h4>{props.title}</h4>
        </div>
        <div className={styles.tagContainer}>{drawPills(props.tags)}</div>
        <div className={styles.subTitleContainer}>
          {props.basedOn && (
            <p>Based on {props.basedOn}</p>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <Link to={`/tool/${props["slug"]["current"]}`}>
              <p>View</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Card);
