import React from "react";
import styles from "./aside.module.scss";
import {NavLink} from 'react-router-dom';
import {useDispatch} from "react-redux";

import {openMenu} from '../../store/actions/general-actions'

const ContentAside = (props) => {

  const dispatch = useDispatch();

  const handleClick = (() => {
    dispatch(openMenu());
  })

  return (
    <React.Fragment>
      <aside className={styles.asideContainer}>
        <ul className={styles.listContainer}>
          {props.menuList.map((option, value) =>
            <li className={styles.itemstyle} key={value}>
            <NavLink
                exact
                to={option.href}
                activeClassName="active-link"
                onClick={()=>handleClick()}
            >
                <p>{option.name}</p>
            </NavLink>
            </li>
          )}
        </ul>
      </aside>
    </React.Fragment>
  );
};

export default ContentAside;
