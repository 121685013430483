export const MENU_OPEN = "MENU_OPEN";
export const SET_CLEARANCE_FILTER = "SET_CLEARANCE_FILTER";
export const SET_DISEASE_FILTER = "SET_DISEASE_FILTER";
export const SET_BIOMARKER_FILTER = "SET_BIOMARKER_FILTER";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SLIDERS = "SET_SLIDERS";
export const HANDLE_MODAL = "HANDLE_MODAL";
export const MAIL_SENT = "MAIL_SENT";
export const MAIL_TOOL_SENT = "MAIL_TOOL_SENT";
export const NEWSLETTER_SENT = "NEWSLETTER_SENT";
export const SET_TOOLS = "SET_TOOLS";
export const SET_SELECTED_TOOL = "SET_SELECTED_TOOL";
