import Card from "../card/card";
import CardSlider from "../card-slider/card-slider";
import FilterBar from "../filter-bar/filter-bar";
import FilterByTag from "../filter-by-tag/filter-by-tag";
import HomepageSection from "../../components/homepage-section/homepage-section";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import styles from "./main-search.module.scss";

import { useSelector, useDispatch } from "react-redux";
import { taggedTool, uniqueSetFilters } from "../../store/actions/general-actions";
import { Helmet } from "react-helmet";
import queryString from "query-string";

const MainSearch = (props) => {
  const myRef = useRef(null);
  const [filterTag, setFilterByTag] = useState("");
  const state = useSelector((state) => state.general);
  const dispatch = useDispatch();
  const url = queryString.parse(props.location.search);

  useEffect(() => {
    dispatch(uniqueSetFilters());
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectFilterTag = (value) => {
    if (filterTag === value) {
      setFilterByTag("");
      return dispatch(taggedTool("", url));
    } else {
      setFilterByTag(value);
      return dispatch(taggedTool(value, url));
    }
  };

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>Search different tools</title>
        <meta name="description" content="Search lala" />
      </Helmet>
      <FilterBar />
      <HomepageSection
        sectionTitle="Search results"
        sectionSubTitle={`We have found ${state.tools.length} ${
          state.tools.length === 1 ? "tool" : "tools"
        } by your chosen criteria. You can edit your results on the filters below:`}
        separator={true}
        searchContainer={true}
      >
        <FilterByTag
          data={state.filters}
          handleSetFilterByTag={(value) => handleSelectFilterTag(value.name)}
          filtered={filterTag}
        />
        {!isEmpty(state.tools) ? (
          <div className={styles.cardsContainer}>
            {state.tools.map((result, key) => {
              return (
                <div className={styles.cardContainer} key={key}>
                  <Card
                    keyProp={key}
                    logo={result.logo}
                    reportimages={(result.reportimages) && result.reportimages.length && result.reportimages[0]}
                    title={result.name}
                    tags={result.tags}
                    basedOn={result.basedOn}
                    slug={result.slug}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.noResults}>
            <p>No tools matching your criteria were found</p>
          </div>
        )}
      </HomepageSection>
      <HomepageSection sectionTitle="Spotlight tools" separator={false}>
        <CardSlider filterSliderName={"Spotlight"} />
      </HomepageSection>
    </div>
  );
};

export default withRouter(MainSearch);
