import React, { useEffect, useRef, useState } from "react";
import ContactForm from "../contact-form/contact-form";
import FilterBar from "../filter-bar/filter-bar";
import ApiResponse from "../../ui/api-response/api-response";
import HomepageSection from "../../components/homepage-section/homepage-section";
import styles from "./main-contact.module.scss";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

const MainContact = (props) => {
  const myRef = useRef(null);
  const state = useSelector((state) => state.general);
  const [formSent, setFormSent] = useState(false);

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const handleSubmit = () => {
    setFormSent(true);
  };

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <FilterBar />
      <div className={styles.sectionContainer}>
        <HomepageSection sectionTitle="Contact" separator={false}>
          <div className={styles.contactInformation}>
            {/* <div className={styles.title}>
                <p>That fluff gets into the owner's eyes:</p>
              </div> */}
            <div className={styles.officeInformation}>
              <h3>EU Offices</h3>
              <div className={styles.separator}>
                <hr size="1" />
              </div>
              <p>
                C/ Roger de Llúria 46, <br />
                Pral. 1ª 08009 Barcelona, Spain <br />
                +34 933 282 007 <br />
              </p>
            </div>
            <div className={styles.officeInformation}>
              <h3>US Headquarters</h3>
              <div className={styles.separator}>
                <hr size="1" />
              </div>
              <p>
                1 International Place, Suite 3700, 02110 <br />
                Boston, MA, United States
              </p>
            </div>
            <div className={styles.additionalInformation}>
              {/* <p>
                  That fluff gets into the owner's eyes. 
                  Mewl for food at 4am poop on couch meow meow, 
                  i tell my human and i am the best leave buried treasure in the sandbox 
                  for the toddlers and munch on tasty moths but good morning sunshine.
                </p> */}
              <p>
                For all general inquiries, questions and feedback feel free to
                contact our team:
                <a
                  href="mailto:support@qmenta.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  support@qmenta.com
                </a>
              </p>
              <p>
                Write us an email and a member of our sales team will reach you
                to discuss how we can support your project:{" "}
                <a
                  href="mailto:info@qmenta.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  info@qmenta.com
                </a>
              </p>
            </div>
          </div>
          {state.contactMailSent ? (
            <div className={styles.contactFormContainer}>
              <ApiResponse
                title={state.sendStatus === "mail_sent" ? "Success" : "Error"}
                message={state.responseMessage}
                condition={
                  state.sendStatus === "mail_sent" ? "success" : "error"
                }
              />
            </div>
          ) : (
            <div
              className={
                formSent
                  ? `${styles.contactFormContainer} ${styles.contactFormContainerOut}`
                  : styles.contactFormContainer
              }
            >
              <ContactForm
                title="contact form"
                handleSubmit={() => handleSubmit()}
              />
            </div>
          )}
        </HomepageSection>
      </div>
    </div>
  );
};

export default MainContact;
