import React from "react";

const backdrop = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "50%",
        zIndex: 10,
        height: "100vh",
        background: "transparent",
      }}
      onClick={() => props.action()}></div>
  );
};

export default backdrop;
