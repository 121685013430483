import Card from "../card/card";
import React, { useEffect, useState, useRef } from "react";
import { MdSwipe } from "react-icons/md";
import styles from "./card-slider.module.scss";
import Slider from "react-slick";
import ChevronLayout from "../../components/chevron-layout/chevron-layout";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CardSlider = (props) => {
  const [removeChevron, setRemoveChevron] = useState(false);
  const sliderRef = useRef(null);
  const isTabletAndDown = useMediaQuery({ maxWidth: 991 });
  const state = useSelector((state) => state.general);
  const [slider, setSlider] = useState([]);

  useEffect(() => {
    setRemoveChevron(isTabletAndDown);
  }, [isTabletAndDown]);

  useEffect(() => {
    const newSlider = state.sliders.find((result) => result.name === props.filterSliderName)
    newSlider && setSlider(newSlider);
    newSlider?.tool?.length && sliderRef.current?.slickGoTo(0);
  }, [state.sliders, props.filterSliderName]);

  const settings = {
    dots: false,
    infinite: true, // return to true with more tools loaded
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    focusOnSelect: false,
    touchMove: false,
    nextArrow: !removeChevron && <ChevronLayout right={true} />,
    prevArrow: !removeChevron && <ChevronLayout left={true} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          focusOnSelect: false,
          infinite: true, // return to true with more tools loaded
          dots: false,
          touchMove: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          focusOnSelect: false,
          infinite: true, // return to true with more tools loaded
          dots: false,
          touchMove: true,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <Slider {...settings} ref={slider => { sliderRef.current = slider; }} style={{ maxHeight: "400px" }}>
        {slider?.tool?.map((result, key) => {
          return (
            <React.Fragment key={key}>
              <Card
                keyProp={key}
                logo={result.logo}
                reportimages={result.reportimages && result.reportimages[0]}
                title={result.name}
                tags={result.tags}
                basedOn={result.basedOn}
                slug={result.slug}
              />
            </React.Fragment>
          );
        })}
      </Slider>
      {removeChevron && (
        <MdSwipe
          style={{
            fontSize: "2rem",
            textAlign: "center",
            width: "100%",
            color: "#00758c",
          }}
        />
      )}
    </div>
  );
};

export default CardSlider;
