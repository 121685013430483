import FilterBar from "../filter-bar/filter-bar";
import HomepageSection from "../../components/homepage-section/homepage-section";
import IconsHome from "../../components/icons-homepage/icons-homepage";
import React, { useEffect, useRef, useState } from "react";
import CardSlider from "../card-slider/card-slider";
import { useMediaQuery } from "react-responsive";
import Poster from "../../assets/images/video.png";
import Play from "../../assets/images/play.png";
import cloud from "../../assets/images/benefits-cloud@2x.png";
import searchable from "../../assets/images/benefits-searchable@2x.png";
import quality from "../../assets/images/benefits-quality@2x.png";
import algorithms from "../../assets/images/benefits-best algorithms@2x.png";
import verAlgorithms from "../../assets/images/benefits-verified algorithms@2x.png";
import aiTools from "../../assets/images/benefits-AiTools@2x.png";
import newsletter from "../../assets/images/newsletter-icon.svg";
import { sendNewsLetterForm } from "../../store/actions/general-actions";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import styles from "./main-home.module.scss";

import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

const MainHome = (props) => {
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const { register, handleSubmit, errors } = useForm();
  const [playVideo, setPlayVideo] = useState(false);
  const [removeSeparator, setRemoveSeparator] = useState(false);
  const isTabletAndDown = useMediaQuery({ maxWidth: 991 });

  useEffect(() => {
    setRemoveSeparator(isTabletAndDown);
  }, [isTabletAndDown]);

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  const onSubmit = async (data) => {
    dispatch(sendNewsLetterForm(data));
  };

  const handlePlay = (el) => {
    el.currentTarget.style.visibility = "hidden";
    el.currentTarget.nextSibling.play();
    setPlayVideo(!playVideo);
  };

  return (
    <div className={styles.container} ref={myRef}>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <div className={styles.slideContainer}>
        <div className={styles.slideRow}>
          <div className={styles.slideImage}>
            <div className={styles.videoDiv}>
              <img
                src={Play}
                alt="play"
                className={styles.playButton}
                onClick={(e) => handlePlay(e)}
              ></img>
              <video
                preload="true"
                controls={playVideo}
                poster={Poster}
                style={{
                  width: `${playVideo ? "100%" : "calc(100% + 4px)"}`,
                  maxWidth: `${playVideo ? "100%" : "calc(100% + 10px)"}`,
                }}
              >
                <source
                  src="https://storage.googleapis.com/bucket-cdns/Tools-Video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className={styles.slideText}>
            <h1>
              AI imaging analysis catalog for neuroimaging and medical imaging
              has state of the art, industry standard, and proprietary AI
              biomarker tools for faster, more objective analysis.
            </h1>
            <h4>
              All the AI imaging analysis tools are easily searchable,
              scientifically validated, and executable on the QMENTA cloud
              platform, anytime, anywhere.
            </h4>
          </div>
        </div>
        <div className={styles.rowBgOverlay}></div>
        <div className={styles.homeFilterBar}>
          <div className={styles.separator}>
            <hr size="2" />
          </div>
          <FilterBar
            home={true}
            title="Apply one or more filters to refine to your area of interest:"
          />
        </div>
        <div className={styles.advenceSvg}>
          <svg
            className={styles.nectarShape}
            fill="true"
            viewBox="0 0 1000 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path d="M 0 14 s 88.64 3.48 300 36 c 260 40 514 27 703 -10 l 12 28 l 3 36 h -1018 z"></path>
            <path d="M 0 45 s 271 45.13 500 32 c 157 -9 330 -47 515 -63 v 86 h -1015 z"></path>
            <path d="M 0 58 s 188.29 32 508 32 c 290 0 494 -35 494 -35 v 45 h -1002 z"></path>
          </svg>
        </div>
      </div>
      <HomepageSection
        sectionTitle="Exclusively from QMENTA"
        separator={!removeSeparator}
      >
        <CardSlider filterSliderName={"Exclusive of QMENTA"} />
      </HomepageSection>
      <HomepageSection
        sectionTitle="Highlighted Partners"
        separator={!removeSeparator}
      >
        <CardSlider filterSliderName={"Body image AI Analysis"} />
      </HomepageSection>
      <div className={styles.sectionContainer}>
        <div className={styles.expects}>
          <HomepageSection
            sectionTitle="Why Choose QMENTA AI Medical Imaging Analysis?"
            sectionSubTitle="Simply and easily access proven, state-of-the-art AI imaging analysis tools to confidently accelerate your research, clinical study or diagnosis. Utilize the most comprehensive catalog of neurology biomarker tools to measure features of the brain, detect and assess disease progression and interventional therapy results."
          >
            <div className={styles.iconsPresentation}>
              <IconsHome icon={cloud} description={`All in One Place`} />
              <IconsHome icon={searchable} description={`Easily Searchable`} />
              <IconsHome icon={algorithms} description={`Regularly Updated`} />
              <IconsHome
                icon={verAlgorithms}
                description={`Best In Class And Scientifically Verified`}
              />
              <IconsHome
                icon={quality}
                description={`All Tools Executable On Our High Quality Cloud Platform`}
              />
              <IconsHome
                icon={aiTools}
                description={`Combine And Run Multiple Tools Simultaneously`}
              />
            </div>
          </HomepageSection>
        </div>
      </div>
      <div className={styles.newsletterBarContainer}>
        <div className={styles.newsletterRow}>
          <div className={styles.descriptionContainer}>
            <h2>Sign up for QMENTA’s newsletter</h2>
            <p>
              Sign up with your email address and get the top monthly industry
              news and our company news and updates.
            </p>
          </div>
          <div className={styles.iconContainer}>
            <img src={newsletter} alt="newsletter-icon" />
          </div>
          {!Cookies.get("newsletter_sent") ? (
            <form
              className={styles.formContainer}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.inputContainer}>
                <h5>Email</h5>
                <input
                  type="text"
                  placeholder="Type your email address"
                  name="email"
                  data-testid="email"
                  defaultValue=""
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Provide a valid email address",
                    },
                  })}
                />

                {errors.email && (
                  <p role="alert" className={styles.error}>
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div className={styles.buttonContainer}>
                <button type="submit" className={styles.button}>
                  <p>Sign Up</p>
                </button>
              </div>
            </form>
          ) : (
            <div className={styles.descriptionContainer}>
              <h2 className={styles.alreadysuscribe}>
                You are already subscribed
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainHome;
