import React from "react";
import EITHealth from "../../assets/images/EITHealth-logo.png";
import LogoQMENTAWhite from "../../assets/images/Logo-QMENTA-White.png";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import styles from "./footer.module.scss";

const Footer = () => {

  return (
    <div style={{ backgroundColor: '#292929' }}>
      <div className={styles.container}>
        <div className={styles.gridRow}>
          <div className={styles.gridCard}>
            <p className={styles.gridCardTitle}>
              <img src={LogoQMENTAWhite} alt="LogoQMENTAWhite" />
            </p>
            <div className={styles.address}>
              <strong><b>US Headquarters</b></strong>
              <p>75 State Street, Suite 100</p>
              <p>Boston, Massachusetts 02109</p>
              <p>+1 339 368 8040</p>
              <br />
              <strong><b>EU Offices</b></strong>
              <p>C/ Roger de Llúria 46, Pral. 1ª</p>
              <p>08009 Barcelona,Spain</p>
              <p>+34 933 282 007</p>
              <br />
              <img src={EITHealth} alt="EITHealth" />
              <div className={styles.disclaimerLinks}>
                <ul>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/qmenta-inc"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaLinkedinIn />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/QMENTA_Inc"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCO0a2PgyT38jCsTNm3wMLwQ"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaYoutube />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/qmenta_inc/?hl=en"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/QMENTA/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.gridRow}>
          <div className={styles.gridCard}>
            <p className={styles.gridCardTitle}><b>Resources</b></p>
            <ul>
              <li>
                <a
                  href="https://www.qmenta.com/blog"
                  rel="noreferrer"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/library"
                  rel="noreferrer"
                  target="_blank"
                >
                  Library
                </a>
              </li>
              <li>
                <a
                  href="https://support.qmenta.com/hc/en-us"
                  rel="noreferrer"
                  target="_blank"
                >
                  Help Center
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/sdk"
                  rel="noreferrer"
                  target="_blank"
                >
                  SDK
                </a>
              </li>
              <li>
                <a
                  href="https://neuroimaging.qmenta.com/industry-news"
                  rel="noreferrer"
                  target="_blank"
                >
                  Newsletter
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.gridCard}>
            <p className={styles.gridCardTitle}><b>About US</b></p>
            <ul>
              <li>
                <a
                  href="https://www.qmenta.com/contact"
                  rel="noreferrer"
                  target="_blank"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/jobs"
                  rel="noreferrer"
                  target="_blank"
                >
                  Jobs
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/press"
                  rel="noreferrer"
                  target="_blank"
                >
                  Press
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/team-qmenta/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Our Team
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/terms-of-service/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/privacy-policy/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.gridCard}>
            <p className={styles.gridCardTitle}><b>Solutions</b></p>
            <ul>
              <li>
                <a
                  href="https://www.qmenta.com/data-management-platform-for-medical-imaging-clinical-trials-and-studies"
                  rel="noreferrer"
                  target="_blank"
                >
                  Data Management System
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/clinical-trials-suite"
                  rel="noreferrer"
                  target="_blank"
                >
                  Clinical Trials
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/imaging-diseases-packages"
                  rel="noreferrer"
                  target="_blank"
                >
                  Neuroimaging Diseases Package
                </a>
              </li>
              <li>
                <a
                  href="https://catalog.qmenta.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  Imaging Biomarkers Catalog
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/neuro-research-suite"
                  rel="noreferrer"
                  target="_blank"
                >
                  Neuro Research
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/clinical-care-suite"
                  rel="noreferrer"
                  target="_blank"
                >
                  Clinical Care
                </a>
              </li>
              <li>
                <a
                  href="https://www.qmenta.com/neuro-data-services"
                  rel="noreferrer"
                  target="_blank"
                >
                  Neuro Data Services
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.copyright}>
        <div className={styles.row}>
          <div className={styles.copyrightText}>
            <p>&copy; {new Date().getFullYear()} QMENTA</p>
            <p>All rights reserved</p>
            <a
              href="https://www.qmenta.com/privacy-policy/"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
